<template>
  <div>
    <div class="container">
      <div>
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6" style="text-align: end;">
            <div class="form-group">
              <div class="input-group mb-3">
                <a-tooltip placement="topLeft" title="Find..">
                  <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filter"
                      placeholder="Find"
                      aria-label
                  />
                </a-tooltip>
                <div class="input-group-append">
                  <a-tooltip placement="topLeft" title="Clean Filters">
                    <a-button
                        style="text-align: end"
                        class="rounded-0"
                        type="primary"
                        @click="filter=''"
                    >
                      <i class="fa fa-eraser" style="margin-right: 10px"/>Clean
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card card-custom">
        <div class="card-header py-3">
          <div class="col-md-6">
            <div class="dropdown d-inline mr-2">
              <label class="mr-2">Filter by</label>
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ search_filter }}
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="position: absolute; transform: translate3d(0px, 29px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='All'">All</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Created'">Created</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Done'">Done</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Taken'">Taken</a>
                <a class="dropdown-item" href="javascript:void(0)" @click="search_filter='Canceled'">Canceled</a>
              </div>
            </div>
          </div>

          <div class="col-md-6" style="text-align: end;float: right">
            <div class="row">
              <div class="col-md-6 offset-4">
                <a-dropdown-button style="margin-left: 45%;">
                  Export
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="exportToExcel">
                      <a-icon type="file-excel" theme="twoTone" twoToneColor="#52c41a"/>
                      EXCEL
                    </a-menu-item>
                    <a-menu-item key="2" v-if="false">
                      <a-icon type="file-pdf" theme="twoTone" twoToneColor="#c41c33"/>
                      PDF
                    </a-menu-item>
                    <a-menu-item key="3" @click="exportToCSV">
                      <a-icon type="file-text" theme="twoTone" twoToneColor="#2771c4"/>
                      CSV
                    </a-menu-item>
                  </a-menu>
                </a-dropdown-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-modal
          @cancel="onCloseModal"
          :title="selected_model.get_id()?'Actualizar orders':'Añadir orders'"
          class="modal-form"
          width="55rem"
          :visible="show_modal_form"
          :destroyOnClose="true"
          on-ok="handleOk"
          :header="null"
          :footer="null"
          :maskClosable="false"

      >
        <orders_form :modal=true :model="selected_model"/>
      </a-modal>

      <a-table
          :columns="columns"
          :rowKey="record => record._id"
          :dataSource="data"
          :loading="loading"
          :pagination="pagination.$data"
      >
        <a slot="action" slot-scope="record" href="javascript:;">
          <action_buttons :object="record" :visible_view="false" :v_instance="self"
                          :class_name="selected_model.class_name()"/>
        </a>
        <div slot="status" slot-scope="record">
          <span v-if="record.status.toLowerCase()==='done'" class="badge badge-success">{{ record.status }}</span>
          <span v-else-if="record.status.toLowerCase()==='created'" class="badge badge-info">{{ record.status }}</span>
          <span v-else-if="record.status.toLowerCase()==='canceled'"
                class="badge badge-danger">{{ record.status }}</span>
          <span v-else class="badge badge-dark">{{ record.status }}</span>
        </div>
        <div slot="paid" slot-scope="record">
          <span v-if="record.paid" class="badge badge-success">P</span>
          <span v-else class="badge badge-danger">NP</span>
        </div>
        <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
          <ul class="list-group">
            <li v-if="partner_id!==''" class="list-group-item"><span class="row"><p class="col-md-2">Client:</p> <p
                class="text-muted form-text col-md-5 text-left" style="font-size: 15px">{{
                record.client_name
              }}</p></span></li>
            <li v-if="client_id!==''" class="list-group-item"><span class="row"><p class="col-md-2">Partner:</p> <p
                class="text-muted form-text col-md-5 text-left" style="font-size: 15px">{{
                record.partner_name
              }}</p></span></li>
            <li class="list-group-item">Paid: <span v-if="record.paid" class="badge badge-success">Paid</span>
              <span v-else class="badge badge-danger">Unpaid</span>
            </li>
          </ul>
        </div>

      </a-table>
    </div>
  </div>
</template>

<script>
import * as utils from "../../../../../entities/utils/utils";
import * as mb from "../../../../../entities/models";
import action_buttons from "../../../../shared/table/action_buttons/action_buttons";
import vantdpagination from "../../../../shared/table/pagination/antd_pagination";
import orders_form from "../form/orders_form";

export default {
  name: "orders_simple",
  provide: function () {
    return {
      close_modal: this.onCloseModal,
      load_data: this.load_data
    }
  },
  props: {
    partner_id: {
      type: String,
      default: ""
    },
    client_id: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      search_filter: "All",
      data: [],
      self: null,
      orders_list: [],
      filter: null,
      columns: mb.statics('Orders').columns_simple,
      loading: false,
      text_select: "Select All",
      selectedRowKeys: [],
      pagination: vantdpagination,
      selected_model: mb.instance('Orders'),
      show_modal_form: false,
      mb
    };
  },
  components: {
    action_buttons,
    orders_form
  },
  watch: {
    search_filter: function (newvalue, oldvalue) {
      const params = newvalue === "All" ? {} : {
        status: newvalue
      }
      this.load_data(params)
    },
    filter: function () {
      this.data = this.orders_list.data.filter(this.filter_data);
    },
    selectedRowKeys: function () {
      if (this.selectedRowKeys.length == this.data.length) {
        this.text_select = "Unselect";
      } else {
        this.text_select = "Seleccionar todo";
      }
    }
  },
  computed: {},
  methods: {
    exportToExcel() {
      utils.exportToExcelVinstance(this)
    },
    exportToCSV() {
      utils.exportToCSV(this)
    },
    onCloseModal(e, reload_data = false) {
      this.selected_model = mb.instance('Orders');
      this.show_modal_form = false;
      reload_data ? this.load_data() : ''
    },
    showModalForm() {
      this.show_modal_form = !this.show_modal_form;
    },
    filter_data(object) {
      return utils.filter_object_column(object, this.filter, this.columns);
    },
    onChange: function (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    showDeleteConfirm() {
      if (this.selectedRowKeys.length == 0) {
        utils.openNotificationWithIcon(
            "error",
            "Delete Selecteds Items",
            "You must select at least one item"
        );
        return;
      }
      let _this = this;
      this.$confirm({
        title: "Delete Selecteds Items?",
        icon: "delete",
        // icon:()=>{return ( <a-icon type="delete" style="color:red"/> )},
        okText: "Si",
        okType: "danger",
        class: "delete_confirm",
        cancelText: "No",
        async onOk() {
          try {
            const response = await mb.statics('Orders').delete_by_ids(
                _this.selectedRowKeys
            );
            utils.process_response(response, "deleted");
            _this.selectedRowKeys = [];
            _this.load_data();
          } catch (error) {
            utils.process_error(error);
            _this.selectedRowKeys = [];
          }
        },
        onCancel() {
        }
      });
    },
    async load_data(params = {}) {
      try {
        this.loading = true;
        if (this.partner_id !== "")
          params.partner = this.partner_id
        if (this.client_id !== "")
          params.client = this.client_id
        const resp = await mb.statics('Orders').list({params: params});
        this.orders_list = resp;
        this.data = this.orders_list.data.filter(this.filter_data);
        this.loading = false;
        this.pagination.total = this.data.length
      } catch (error) {
        utils.process_error(error);
        this.loading = false;
      }
    },


    onEditing(model) {
      this.selected_model = mb.instance('Orders', model);
      this.showModalForm();
    }
  },

  mounted() {
    this.loading = true;
    this.data = [];
    this.load_data();
    this.self = this;
    this.pagination.$options.props = {
      load_data: this.load_data
    };
  }
};
</script>

<style>
@import "orders_list.css";

.ant-table-expand-icon-th, .ant-table-row-expand-icon-cell {
  width: 0px !important;
  min-width: 0px !important;
  text-align: left !important;
}
</style>

